@import "../../sass/global/variables.scss";
@import "../../sass/global/_breakpoint.scss";
.searchBox {
  position: relative;

}

@media (max-width: 1024px) {
  .searchBox {
    form {
      .form-row {
        position: relative;
      }
    }
    button {
      width: 100%;
      &::before {
        left: calc(100% - 49px);
      }
    }
    .search-button {
      width: 55px;
    }
    input {
      // font-size: 14px;
      font-size: 12px;
    }
    .suggestion_wrapper {
      width: 100%;
    }
    .suggestions {
      li {
        font-size: 14px;
      }
    }
  }
}
form {
    margin-top: 10px;
 
  
  }
  .form-row {
    display: flex;
  }
  .search-input {
    width: 100%;
    overflow: hidden;
    font-family: "Roboto";
  }
  input {
    height: 50px;
    border-radius: 0;
    background-color: $flip-white;
    color: $bayer-dark-blue;
    &:focus {
      border-color: $bayer-mid-blue;
    }
  }


.no-suggestions {
    color: $medium-gray;
    padding: 0.5rem;
    display: none;
  }
  .suggestion_wrapper {
    position: relative;
    z-index: 10;
    width: calc(83.33% + 55px);
    max-height: 300px;
    overflow-y: auto;
    word-break: break-word;
    border-bottom: 1px solid #f5f0f0;
    border-radius: 8px;
  }

  .suggestions {
    background-color: $white;
    border: 1px solid #ebe2e2;
    border-top-width: 0;
    list-style: none;
    padding-left: 0;
    text-align: left;
    margin-bottom: 0;
    border-bottom: 0;
    li {
      padding: 0.5rem;
      color: $bayer-dark-blue;
      font-family: "Roboto";
      &:hover {
        background-color: rgba(0, 97, 127, 0.3);
        cursor: pointer;
        font-weight: 500;
      }
    }
    .item-desc {
      font-family: "Roboto";
  font-style: italic;
      color: $bayer-mid-blue;
    }
    li:not(:last-of-type) {
      border-bottom: 1px solid #ebe2e2;
    }
  }

  .navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
    background: transparent;
    color: #fff;
}

.dropdown {
  height: 100%;
  font-family: "Roboto";
  button.btn {
    font-family: "Roboto";
    background-color: $bayer-mid-blue ;
    border-color: $bayer-mid-blue;
    height: 100%;
    color:black;
    box-shadow: none;
    &::after {
      vertical-align: 1px;
    }
  }
  .dropdown-menu {
    padding: 0;
    min-width: 200px;
    .dropdown-item {
      border-bottom: 1px solid rgba(0, 97, 127, 0.15);
      color: black;
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      span {
        margin-right: 10px;
        .bi-power {
          color: $bayer-fuchsia;
        }
        .bi-person-circle,
        .bi-power,
        .bi-bookmark-fill {
          font-size: 16px;
        }
      }
      a {
        font-family: "Roboto";
        text-decoration: none;
        color: $bayer-mid-blue;
        font-size: 16px;
      }

      &:active,
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        color:black;
      }
    }
  }
  .dropdown-toggle {
    border: 0;
    padding: 0;
    color: black !important;
    font-weight: 300;
}
}
.profile-dropdown{
    transform: translate(42px, 46px) !important;
    box-shadow: 0px 3px 7px 0px #00000033 !important;
}
