//$white: #fff;
//$blue-lagoon: #00617f;
/* Utilities */
$hand: pointer;
$white: #fff;
$black: #000;

/* Colors */
$dark-blue: #037fc2;
$bayer-blue: #0091df;
$bayer-dark-blue: #10384f;
$ink-blue: lighten($bayer-dark-blue, 20%);
$arapawa: #274b5f;
$grayish-blue: #bfd7df;
$bayer-bright-blue: #00bcff;
$bayer-bright-blue-screen-text: #00bcff;
$pacific-blue: #0090c5;
$bayer-bright-blue-opacity: rgba(0, 188, 255, 0.05);
$bayer-bright-blue-opacity-45: rgba(0, 188, 255, 0.45);
$bayer-raspberry-text-opacity-45: rgba(222, 0, 67, 0.45);
$cyprus: #0d3851;
$dark-purple: #624963;
$mamba: #796d82;
$alice-blue: #f2f7f9;
$bayer-fuchsia: #ff3162;
$bayer-raspberry: #d30f4b;
$medium-pink: #fb1250;
$violet: rgba(16, 56, 79, 0.8);
$bayer-mid-blue: #00617f;
//$bayer-mid-blue-screen-text: #00617f;
$bayer-dark-purple: #443247;
$oxford-blue: #0d2a40;
$light-purple: #443147;
$cannon-pink: #834283;
$dull-blue: #3b5b9b;
$bright-blue: #2caae0;
$red: #e52c26;
$cerulean: #0073b0;
$orange: #ffa502;
$yellow-green: #88ca36;
$bright-green: #89d329;
$regal-blue: #1b4257;
$regal-blue-light: #1c4257;
$bayer-green: #66b512;
$facebook-blue: #3c5b9b;
$twitter-blue: #2daae1;
$linkedin-blue: #0072b0;
$pinrest-red: #bd081c;
$googleplus-red: #d95032;
$white-smoke: #f7f7f7;
$tangaroa: #0f2a3e;
$border-grey: lighten($tangaroa, 70%);
$black-bean: #001a00;
$mardi-gras: #350035;
$debian-red: #d20f4c;
$bayer-mid-purple: #624963;
$pattens-blue: #cfd7dc;
$ash: #495c68;
$alice-blue: #f5fafd;
$gray: #a8a8a8;
$cinder: #212529;
$scrollbar-track-gray: rgba(168, 168, 168, 0.25);
$gray-50: rgba(168, 168, 168, 0.5);
$gray-25: rgba(168, 168, 168, 0.25);
$gray-10: rgba(168, 168, 168, 0.1);
$bayer-mid-blue-light: rgba(0, 97, 127, 0.05);
$shadow-gray: lighten($black, 93%);
$dirty-purple: #4d3c50;
$dirty-purple-two: #564659;
$dark-slate-blue: #103b4f;
$inactive-text-black-light: rgba(0, 0, 0, 0.18);
$box-shadow-gray: rgba(0, 0, 0, 0.16);
$modal-bg-black: rgba(0, 0, 0, 0.75);
$modal-body-bg: rgba(247, 247, 247, 0.55);
$annotation-black-overlay: rgba(0, 0, 0, 0.8);
$annotation-white-overlay: rgba(255, 255, 255, 0.8);
$annotation-raspberry-overlay: rgba(211, 15, 75, 0.9);
$annotation-mid-purple-overlay: rgba(98, 73, 99, 0.9);
$annotation-mid-green-overlay: rgba(43, 102, 54, 0.9);
$annotation-green-overlay: rgba(102, 181, 18, 0.9);
$annotation-mid-blue-overlay: rgba(0, 97, 127, 0.9);
$annotation-blue-overlay: rgba(0, 145, 223, 0.9);
$light-grey: #b7c3c9;
$dim-gray: #e5eaec;
$mild-grey: #cecece;
//$bayer-green: #66b512;
$bayer-dark-green: #004422;
$bayer-dark-green-screen-text: #004422;
$bayer-mid-green: #2b6636;
$bayer-bright-green: #89d329;
$search-text: #103b4f;
$search-border: #ebebeb;
$search-suggestion: #f2fcff;
$footer-border-top: #d3d8dc;
$download-bar-background: #f2f7f9;
$download-bar-text-font: #0d2a40;
$dark-indigo: #0d2a40;
$deep_sky_blue: #15adff;
$bayer-green-screen-text: #108000;
$bayer-raspberry-screen-text: #de0043;
$bayer-blue-screen-text: #0075a6;
$pink-swan: #b2b2b2;
$pink-swan-2: #b7b5b5;
$hover-grey: #dce8eb;
$gainsboro: #dcdcdc;
$very-light-grey: #cccccc;
$bondi-blue: #0083c2;
$silver: #c3cdd3;
$pale-blue: #d7eff9;
$dark-gray: #565659;
$bayer-language-selected: #4fb3ef;
$medium-gray: #999;
$modal-gray: rgba(16, 56, 79, 0.9);
$medium-cyan: #006180;
$mild-gray: #f5f5f5;
$fedora: #634964;
$pale-blue: #d1e0e7;
$stock-price-green: #67b513;
$science-blue: #0779bf;
$legal-links: #453247;
$science-blue: #0779bf;
$kelly-green: #3d8818;
$razzmattazz: #de104f;
$infobox-blue: rgba(191, 215, 223, 0.2);
$azure-popup-button: #ecf0f1;
$light-shade-gray: #ddd;
//$dark-grey: #a8a8a8;
$blue-lagoon: #006281;
$gray-80: #f8f8f8;
$text-link-blue: #56bdf3;
$dull-blue-menu: #264b5e;
$download-tool-blue: #577383;
$download-tool-year-bg: #f2f5f6;
$download-tool-year-border: #ced6db;
$magenta: #705971;
$gainsboro: #e0e0e0;
$grayish-blue: #6a8391;
$vk: #45668e;
$weixin: #9de60b;
$weibo: #ce1126;
$xing: #026466;
$soft-navy-screen-text: #52558b;
$bayer-fuchsia-screen-text: #ff3162;
$medium-magenta: #db7cee;
$blue-magenta-screen-text: #bdbdc0;
//$gray-screen-text: #a8a8a8;
$evening-sea: #2f5264;
$datagrid-row-alt: #eeeeee;
$datagrid-border: #757575;
$bayer-fuchsia-screen-text: #ff3162;
$evening-sea: #2f5264;
$dull-blue-menu: #264b5e;
$pale-cerulean: #c0dce8;
$form-file-upload: #62496333;
$medium-magenta: #db7cee;
$prussian-blue: #004060;
$flip-blue: #337ab7;
$flip-white: #f3f3f3;
$flip-img-blue: #cdebf5;
$e-card-blue: #def6ff;
$grey-swan: #b8b8b8;
$jet-gray: #a09d9d;
$dark-indigo: #0d2a40;
$prussian-blue: #004060;
$dc-dim-gray: #707070;

/* Bayer Fonts */
$ultra-light: "Neue Helvetica", sans-serif;
$thin: "Neue Helvetica", sans-serif;
$thin-italic: "Neue Helvetica", sans-serif;
$normal: "Neue Helvetica", sans-serif;
$normal-italic: "Neue Helvetica", sans-serif;
$semi-bold: "Neue Helvetica", sans-serif;
$semi-bold-medium: "Neue Helvetica", sans-serif;
$semi-bold-italic: "Neue Helvetica", sans-serif;
$bold: "Neue Helvetica", sans-serif;
$bold-italic: "Neue Helvetica", sans-serif;

/* Icon Fonts */
$font-awesome: "FontAwesome";
$glyphter: "Glyphter";

$search-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 30 30'%3E%3Ccircle id='search' cx='18' cy='12' r='10' style='stroke:%23ffffff;stroke-width:4;fill:none;'/%3E%3Cline x1='1.2' y1='27.8' x2='11' y2='18.1' style='stroke:%23ffffff;stroke-width:4;fill:none;'/%3E%3C/svg%3E");
$search-icon-thin: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 30 30'%3E%3Ccircle id='search' cx='18' cy='12' r='10' style='stroke:%23ffffff;stroke-width:2;fill:none;'/%3E%3Cline x1='1.2' y1='27.8' x2='11' y2='18.1' style='stroke:%23ffffff;stroke-width:2;fill:none;'/%3E%3C/svg%3E");

$content-width: 53.5%;
$sidebars-width: 23.25%;

/* File Added for over-riding width of content in content with sidebars in content-page layout */

$bayer-xlcontent-width: 61.01%;
$bayer-xlsidebars-width: 19.49%;

$bayer-lcontent-width: 60.74%;
$bayer-lsidebars-width: 19.63%;
