@import "./sass/global/variables.scss";
@import "./sass/global/_breakpoint.scss";
@font-face {
  font-family: "Neue Helvetica Narrow";
  src: url("./fonts/NeueHelvetica/cc0b18f1-39d1-4580-b602-5a15f141c052.eot?#iefix");
  src: url("./fonts/NeueHelvetica/cc0b18f1-39d1-4580-b602-5a15f141c052.eot?#iefix")
      format("eot"),
    url("./fonts/NeueHelvetica/685c012e-b603-41bb-9f76-d7e1cab76f53.woff2")
      format("woff2"),
    url("./fonts/NeueHelvetica/0248a27d-0abf-4b74-a3c5-47a73e09098d.woff")
      format("woff"),
    url("./fonts/NeueHelvetica/47657cdc-7580-4429-a42b-88917e1ee633.ttf")
      format("truetype"),
    url("https://shared.bayer.com/fonts/western/0cb85325-f0de-4961-88cd-36759752dddc.svg#0cb85325-f0de-4961-88cd-36759752dddc")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Neue Helvetica Narrow Italic";
  src: url("./fonts/NeueHelvetica/b74c519b-e2f4-426d-983e-73e6179fb1c3.eot?#iefix");
  src: url("./fonts/NeueHelvetica/b74c519b-e2f4-426d-983e-73e6179fb1c3.eot?#iefix")
      format("eot"),
    url("./fonts/NeueHelvetica/176a80d2-cdc4-4f80-a617-24026f69062c.woff2")
      format("woff2"),
    url("./fonts/NeueHelvetica/8591aeba-737a-4116-9397-585c23ebe051.woff")
      format("woff"),
    url("./fonts/NeueHelvetica/e2fecb5c-a4e8-4326-99d3-04ccc5a2fe7b.ttf")
      format("truetype"),
    url("https://shared.bayer.com/fonts/western/04afbdb8-b0c5-4f69-ad0a-d225e45f8882.svg#04afbdb8-b0c5-4f69-ad0a-d225e45f8882")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Neue Helvetica";
  src: url("./fonts/NeueHelvetica/8827e223-8d96-4750-8b0c-8ef828fb1f0f.eot?#iefix");
  src: url("./fonts/NeueHelvetica/8827e223-8d96-4750-8b0c-8ef828fb1f0f.eot?#iefix")
      format("eot"),
    url("./fonts/NeueHelvetica/0128d8b7-d00b-4315-8daa-970f97ecac64.woff2")
      format("woff2"),
    url("./fonts/NeueHelvetica/803af1a9-a09b-4de5-ba36-e3d228fb5bc4.woff")
      format("woff"),
    url("./fonts/NeueHelvetica/831880ae-4958-487d-bc2f-aa2c1a0636e7.ttf")
      format("truetype"),
    url("https://shared.bayer.com/fonts/western/3513edb4-f4ad-4340-85e6-0cea8ca12237.svg#3513edb4-f4ad-4340-85e6-0cea8ca12237")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Neue Helvetica Italic";
  src: url("./fonts/NeueHelvetica/f58764aa-e314-4f4f-bcd5-2feb02b22e08.eot?#iefix");
  src: url("./fonts/NeueHelvetica/f58764aa-e314-4f4f-bcd5-2feb02b22e08.eot?#iefix")
      format("eot"),
    url("./fonts/NeueHelvetica/6c334865-778f-4cb5-9b2e-cfdfb2d2c205.woff2")
      format("woff2"),
    url("./fonts/NeueHelvetica/ca10c83d-ebd5-4705-8f95-673919515083.woff")
      format("woff"),
    url("./fonts/NeueHelvetica/ab661d69-d776-4fbf-94fe-f7d2950ddc70.ttf")
      format("truetype"),
    url("https://shared.bayer.com/fonts/western/72289c2d-620c-4c18-93d5-5938530db5e3.svg#72289c2d-620c-4c18-93d5-5938530db5e3")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Neue Helvetica Medium";
  src: url("./fonts/NeueHelvetica/285b0922-a3d2-4550-b29d-b4f74a9611e4.eot?#iefix");
  src: url("./fonts/NeueHelvetica/285b0922-a3d2-4550-b29d-b4f74a9611e4.eot?#iefix")
      format("eot"),
    url("./fonts/NeueHelvetica/823e6839-53d8-41b9-a5a1-5f05833acd12.woff2")
      format("woff2"),
    url("./fonts/NeueHelvetica/92679273-9798-4d27-893b-d53dfd2bb4c7.woff")
      format("woff"),
    url("./fonts/NeueHelvetica/e4fb2994-44c3-40fe-bec7-9c4fa593b799.ttf")
      format("truetype"),
    url("https://shared.bayer.com/fonts/western/ea22c7fc-8a36-484f-85e6-d014662a2273.svg#ea22c7fc-8a36-484f-85e6-d014662a2273")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Neue Helvetica Medium Italic";
  src: url("./fonts/NeueHelvetica/cd36001a-de1e-4773-adcf-a6e589f0705a.eot?#iefix");
  src: url("./fonts/NeueHelvetica/cd36001a-de1e-4773-adcf-a6e589f0705a.eot?#iefix")
      format("eot"),
    url("./fonts/NeueHelvetica/bcfc49d1-ee2f-4fcc-8322-99607f4a7007.woff2")
      format("woff2"),
    url("./fonts/NeueHelvetica/fd783951-0929-49f5-9cdd-5b34008bb362.woff")
      format("woff"),
    url("./fonts/NeueHelvetica/035a7d84-08c6-4e91-9dbc-a881c1d76333.ttf")
      format("truetype"),
    url("https://shared.bayer.com/fonts/western/553e1a25-3907-46e7-9bec-d7b74e7e05eb.svg#553e1a25-3907-46e7-9bec-d7b74e7e05eb")
      format("svg");
  font-display: swap;
}
//can use if needed going forward
// @font-face {
//   font-family: "Neue Helvetica";
//   font-weight: 500;
//   font-style: normal;
//   src: url("./fonts/NeueHelvetica/5abe98e8-3e80-4fd6-b27b-6ec4c6385461.eot?#iefix");
//   src: url("./fonts/NeueHelvetica/5abe98e8-3e80-4fd6-b27b-6ec4c6385461.eot?#iefix") format("eot"), url("./fonts/NeueHelvetica/c86f4d57-4caf-4c6f-9554-7926dd67e3f1.woff2") format("woff2"), url("./fonts/NeueHelvetica/92c69f6c-0185-4eb2-800e-1ee3947ca4dd.woff") format("woff"), url("./fonts/NeueHelvetica/e11c48d3-bed3-4b35-9d1e-1a5c7afe80e3.ttf") format("truetype"), url("https://shared.bayer.com/fonts/western/394a4a67-cf1b-44ee-a02e-68dc9752d99e.svg#394a4a67-cf1b-44ee-a02e-68dc9752d99e") format("svg");
//   font-display: swap
// }

@font-face {
  font-family: "Neue Helvetica Bold";

  src: url("./fonts/NeueHelvetica/e276fe3d-01c5-4575-99f1-3fc0780d5417.eot?#iefix");
  src: url("./fonts/NeueHelvetica/e276fe3d-01c5-4575-99f1-3fc0780d5417.eot?#iefix")
      format("eot"),
    url("./fonts/NeueHelvetica/a61c00c6-86e7-4fa9-ac6b-476548df9cb8.woff2")
      format("woff2"),
    url("./fonts/NeueHelvetica/7fd00069-f615-471e-8089-b0bab013cfd9.woff")
      format("woff"),
    url("./fonts/NeueHelvetica/7c110d92-b5c8-416b-b5c6-5f1d6c3a720a.ttf")
      format("truetype"),
    url("https://shared.bayer.com/fonts/western/ee27a720-58cd-465f-b85c-1a5b5bdee597.svg#ee27a720-58cd-465f-b85c-1a5b5bdee597")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Neue Helvetica Bold Italic";
  src: url("./fonts/NeueHelvetica/21a97ece-dadb-4f5e-98d9-51ebcbd7ac41.eot?#iefix");
  src: url("./fonts/NeueHelvetica/21a97ece-dadb-4f5e-98d9-51ebcbd7ac41.eot?#iefix")
      format("eot"),
    url("./fonts/NeueHelvetica/6a88d203-cbbc-42e9-b4b7-195631718870.woff2")
      format("woff2"),
    url("./fonts/NeueHelvetica/502de8c5-bac6-4ef6-9a65-4893fe147d51.woff")
      format("woff"),
    url("./fonts/NeueHelvetica/05b43b63-059e-407d-96d0-fa69d2eb6ec2.ttf")
      format("truetype"),
    url("https://shared.bayer.com/fonts/western/440b047b-d7cc-44db-b119-817f01483587.svg#440b047b-d7cc-44db-b119-817f01483587")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Neue Helvetica Light";
  src: url("./fonts/NeueHelvetica/418d2e49-6a65-426f-b58c-5f601f8557f1.eot?#iefix");
  src: url("./fonts/NeueHelvetica/418d2e49-6a65-426f-b58c-5f601f8557f1.eot?#iefix")
      format("eot"),
    url("./fonts/NeueHelvetica/ec6281a0-c9c4-4477-a360-156acd53093f.woff2")
      format("woff2"),
    url("./fonts/NeueHelvetica/11066b40-10f7-4123-ba58-d9cbf5e89ceb.woff")
      format("woff"),
    url("./fonts/NeueHelvetica/cc9b0eb1-551c-4c84-b241-fc7934a0bd1d.ttf")
      format("truetype");
}

body {
  margin: 0;
  background: #f2edf3 !important;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .cc-window {
    &.cc-banner {
      background-color: $bayer-blue;
      .cc-btn {
        &.cc-allow {
          background-color: $bayer-bright-green;
        }
        &.cc-dismiss {
          background-color: $bayer-bright-green;
        }
        &.cc-deny {
          background-color: $bayer-bright-green;
        }
        &:hover {
        text-decoration: underline;
        }
      }
    }
  }
  .cc-banner {
    .cc-message {
      font-family: "Neue Helvetica";
    }
  }
  .cc-btn {
    font-family: "Neue Helvetica";
  }
  .cc-theme-custom {
    display: flex;
    flex-direction: column !important;
    top: 50% !important;
    max-width: 50% !important;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    min-width: 300px;
    .cc-compliance {
      width: 100%;
      margin-top: 20px;
      justify-content: space-around;
      .cc-btn {
        flex: 0 0 33%;
      }
    }
  }
}

@media (max-width: 736px) {
  body {
    .cc-theme-custom {
      max-width: 100% !important;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
